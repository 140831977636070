import { useQuery, gql } from '@apollo/client';
import {
  fieldSorter,
  sortJindo,
  hagOrder,
  hagJindoOrder,
  makeTd,
} from './func';

export function StudentsTable({ printKind }) {
  const { loading, error, data } = useQuery(gql`
    {
      chul {
        name
        schoolHag
        schoolShort
        hag
        age
        ban
        dob
        apt
        otherBan
        ajNum
        payday
        fee
        rNumber
        rSender
        violinAttend
        attend
        attendNum
        jBook
        jNum
      }
    }
  `);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let chul = [];
  if (printKind === '이름순') {
    chul = [];
    chul = [...data.chul];
  }

  if (printKind === '학년순') {
    chul = [];
    chul = hagOrder(data);
  }

  if (printKind === '결제일순') {
    chul = [];

    chul = [...data.chul];
    chul = chul.sort(fieldSorter(['payday', 'name']));
  }

  if (printKind === '진도순') {
    chul = [];
    let chul1 = [];
    chul1 = [...data.chul];
    chul1 = chul1.filter(s => s.otherBan !== "바만"); // 진도순에 바이올린반은 제외
    chul1 = chul1.map(({ jNum, ...rest }) => ({ ...rest, jNum: +jNum }));
    chul = sortJindo(chul1, false, true);
  }

  if (printKind === '학년별진도순') {
    chul = [];
    chul = hagJindoOrder(data);
  }

  if (printKind === '생월순') {
    chul = [];
    let chul1 = [];
    chul1 = [...data.chul];
    chul1 = chul1.map((s) => ({
      ...s,
      mob: s.dob === null || s.dob === '' ? 0 : +s.dob.substring(5, 7),
    }));

    chul = chul1.sort(fieldSorter(['mob', 'name']));
  }

  return (
    <table style={{ width: '100%', lineHeight: '14px', marginLeft: '10px' }}>
      <thead>
        <tr>
          {printKind !== '학년순' &&
            printKind !== '진도순' &&
            printKind !== '학년별진도순' &&
            printKind !== '결제일순' && <th width="1%"></th>}
          <th width="1%">결</th>
          <th width="1%">no</th>
          <th width="7%">이름</th>
          <th width="7%">학년</th>
          <th width="4%">반</th>
          {printKind !== '결제일순' && <th width="4%">ID</th>}
          {/* <th class='violin' width="6%">violin</th> */}
          <th width="8%">피아노</th>
          {printKind !== '결제일순' && <th width="5%">교재</th>}
          {printKind !== '결제일순' && <th width="2%">진</th>}
          {printKind !== '결제일순' && <th width="9%">생년월일</th>}
          {printKind !== '결제일순' && <th width="6%">아파트</th>}
          {printKind === '결제일순' && (
            <>
              <th width="6%">납부일</th>
              <th class='rSender' width="6%">입금자</th>
              <th width="10%">현금영수증</th>
            </>
          )}
          {printKind === '결제일순' && <th>제로페이</th>}
          {printKind !== '결제일순' && <th>기타</th>}
        </tr>
      </thead>
      <tbody>
        {chul.map((row, idx) => {
          if (row.name === 'emptyLine')
            return (
              <tr key={idx} className="gap">
                {makeTd(12)}
                {printKind !== '학년순' &&
                  printKind !== '진도순' &&
                  printKind !== '학년별진도순' && <td></td>}
                {printKind === '결제일순' && makeTd(3)}
              </tr>
            );
          else
            return (
              <tr key={row.name}>
                {printKind !== '학년순' &&
                  printKind !== '진도순' &&
                  printKind !== '학년별진도순' &&
                  printKind !== '결제일순' && (
                    <td className="num">{idx + 1}</td>
                  )}
                <td className="payday">
                  {(row.payday === 0) | (row.payday === null) ? '' : row.payday}
                </td>
                <td className="fee">
                  {(row.fee === '') | (row.fee === null) ? '' : row.fee / 10000}
                </td>
                <td
                  className={
                    row.otherBan === '바만' ? 'name violinOnly' : 'name'
                  }
                >
                  {row.name}
                </td>
                <td>
                  {row.schoolShort === '성인' ? row.schoolShort : row.schoolHag}
                </td>
                <td>{(row.ban === 0) | (row.ban === null) ? '' : row.ban}</td>
                {printKind !== '결제일순' && <td>{row.ajNum}</td>}
                {/* <td className="violinAttend">{row.violinAttend}</td> */}
                <td className="attend">{row.attend}</td>
                {printKind !== '결제일순' && <td className="jBook">{row.jBook}</td>}
                {printKind !== '결제일순' && <td>{row.jNum}</td>}
                {printKind !== '결제일순' && <td className="dob">{row.dob && row.dob.substring(2, 10)}</td>}
                {printKind !== '결제일순' && <td>{row.apt}</td>}
                {printKind === '결제일순' && (
                  <>
                    <td></td>
                    <td>{row.rSender}</td>
                    <td className="rNumber">{row.rNumber}</td>
                  </>
                )}
                <td></td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
}
