import React, { useState } from 'react';
import { render } from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { StudentsTable } from './studentTable.js';
import './index.css';

const client = new ApolloClient({
  //uri: "https://gma-orig-server.herokuapp.com/graphql",
  uri: "https://gma-orig-server.fly.dev/graphql",
  //uri: 'http://localhost:3900/graphql',
  cache: new InMemoryCache(),
});

function App() {
  const [printKind, setPrintKind] = useState('학년별진도순');
  const kind = [
    '이름순',
    '학년순',
    '결제일순',
    '학년별진도순',
    '진도순',
    '생월순',
  ];
  return (
    <ApolloProvider client={client}>
      {/* <page size="A4"> */}
      <div>
        <div className="noPrint">
          {kind.map((k) => (
            <button
              key={k}
              className={printKind === k ? 'yellow' : 'gray'}
              onClick={() => setPrintKind(k)}
            >
              {k}
            </button>
          ))}
        </div>
        <div>
          <h2>{`2023년 3월 출석부 (${printKind})`}</h2>
          <StudentsTable printKind={printKind} />
        </div>
      </div>
      {/* </page> */}
    </ApolloProvider>
  );
}

render(<App />, document.getElementById('root'));
